<template>
    <div id="accountPhoneRecords">
        <div>
            <div style=" padding: 20px 35px 10px 35px; ">
                <table class="table b-table table-hover mb-0" v-bind:class="{'table-striped':loaded}" >
                    <thead>
                    <tr>
                        <th class="w-10 text-center">{{msg("Type")}}</th>
                        <th class="w-25 text-center">{{msg('Source')}}</th>
                        <th class="w-25 text-center">{{msg('Destination')}}</th>
                        <th class="w-20 text-center">{{msg('Duration')}}</th>
                        <th class="w-25 text-center">{{msg('Time')}}</th>
                        <th class="w-25 text-center">{{msg('Status')}}</th>
                    </tr>
                    </thead>
                </table>
                <perfect-scrollbar style="max-height: 600px">
                    <table class="table b-table table-hover" v-bind:class="{'table-striped':loaded && records != null && records.length > 0}">
                    <tbody>
                    <!--Table-->
                    <template v-if="loaded">
                        <tr v-for="call in records" v-bind:key="call.id" >
                            <td class="w-10 text-center p-0">
                                <i class="material-icons" :style="[call.source == phone? {'color':'#3900ff'}:{'color':'#00ff4f'}]" style="margin-top: 5px">
                                    {{call.source == phone ? "phone_callback" : "phone_forwarded"}}
                                </i>
                            </td>
                            <td class="w-25 text-center">{{call.source}}</td>
                            <td class="w-25 text-center">{{call.destination}}</td>
                            <td class="w-20 text-center">{{addZero(parseInt(call.duration/60))}}:{{addZero(call.duration%60)}}</td>
                            <td class="w-25 text-center">{{call.creationtime}}</td>
                            <td class="w-25 text-center">
                            <!-- {{call.statusName}}-->
                                <select  style="width:100%;cursor: pointer;background-color: transparent" v-model="call.status" @change="updateStatus(call.id,call.status)">
<!--
                                    <md-option value=""></md-option>
-->
                                    <option value="0" >{{msg("no status")}}</option>
                                    <option v-for="(s,id) in getStatus" v-bind:value="id" v-bind:key="id">{{s}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr v-if="records == null || records.length ==0">
                            <td colspan="5" class="border" style="border-top: none">
                                <div class="text-center" style="height: 400px">
                                    <div class="d-inline-block" style="margin-top: 20%">
                                        {{msg("No record found")}}
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </template>
                    <!--Error-->
                    <template v-else-if="loaded !== true && error !== ''">
                        <tr class="table-loading" style="background-color: white">
                            <td colspan="9">
                                <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
                                    <strong>{{error}}</strong>
                                </b-alert>
                            </td>
                        </tr>
                    </template>
                    <!--Loading-->
                    <template v-else>
                        <tr class="table-loading">
                            <td colspan="9">
                                <p>
                                    <b-spinner label="Spinning"/>
                                    {{msg("loading")}}
                                </p>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
                </perfect-scrollbar>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "phoneRecords",
        props: {
            phone: String,
        },
        watch: {},
        data: () => ({
            loaded: false,
            error: '',
            recordType: 'all',
            records: [],
            //Listeners
            listeners: {load:()=>{}}
        }),
        created() {
            //this.loadData();//Debug
            this.$root.$on('crm::client::phone::load',(this.listeners.load = () => {
                this.loaded = false;
                this.loadData();
            }));
        },
        beforeDestroy() {
            this.$root.$off('crm::client::phone::load',this.listeners.load)
        },
        computed: {
            ...mapGetters('translator', ['msg']),
            ...mapGetters('phone', ['getStatus']),
        },
        methods: {
            ...mapActions('phone', ['getPhoneRecords', 'get','update']),
            loadData() {
                if (this.phone == null || this.phone == "")
                    return this.error = this.msg("Invalid phone number");
                this.$emit("update-start",true);
                let $this = this;
                this.getPhoneRecords({filter: {phone: this.phone}, amountPerPage: 10}).then((r) => {
                    $this.records = r;
                    $this.loaded = true;
                }, (e) => $this.error = e).finally(()=> $this.$emit("update-finish",false));

            },
            addZero(number) {
                if (number == 0)
                    return "00";
                if (number < 10 && number > 0)
                    return "0" + number.toString();

                return number
            },
            updateStatus(id,status){
                let $this=this;
                //add to the arguments ,sendmail:sendmail
                this.update({id:id,status:status}).then(()=>{


                },(e) => $this.error = e);
            }
        }
    }
</script>

<style scoped>

</style>
