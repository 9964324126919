<template>
    <div class="d-inline-block position-relative" style="width: 100%">
        <span class="address-auto-comple">
            <google-places-autocomplete @resultChanged="onAddressSelect" @resultCleared="onAddressSelect(null)"
                                        :country="county" :show-list="focus">
                <div slot="input" slot-scope="{ context, events, actions }">



                    <template v-if="newStyle">

                          <b-form-input
                              style="width: 100%"
                              v-bind:value="value"
                              @focus="()=>{events.inputHasReceivedFocus(); focus=true}"
                              @blur="onBlur"
                              @input="(v)=>{context.input = v;onInput(v); events.inputHasChanged()}"
                              @keydown.enter.prevent="actions.selectItemFromList"
                              @keydown.down.prevent="actions.shiftResultsSelection"
                              @keydown.up.prevent="actions.unshiftResultsSelection"
                              type="search"
                              :id="`locationInput_${uniqueId}`"
                              autocomplete="no"
                          ></b-form-input>


                    </template>


                    <template v-else>

                        <md-input
                            style="width: 100%"
                            v-bind:value="value"
                            @focus="()=>{events.inputHasReceivedFocus(); focus=true}"
                            @blur="onBlur"
                            @input="(v)=>{context.input = v;onInput(v); events.inputHasChanged()}"
                            @keydown.enter.prevent="actions.selectItemFromList"
                            @keydown.down.prevent="actions.shiftResultsSelection"
                            @keydown.up.prevent="actions.unshiftResultsSelection"
                            type="search"
                            :id="`locationInput_${uniqueId}`"
                            autocomplete="no"
                        ></md-input>

                    </template>


                </div>
                <div style="background-color: #00acc1">
                    <span slot="item" slot-scope="{ place }">
                        {{ place.description }}
                    </span>
                </div>
                <div style="background-color: #00c853">
                    <span slot="activeItem" slot-scope="{ place }">
                        {{ place.description }}
                    </span>
                </div>
            </google-places-autocomplete>
        </span>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import GooglePlacesAutocomplete from "./vue-better-google-places-autocomplete/GooglePlacesAutocomplete";

    export default {
        name: "addressAutoComplete",
        props: {
            value: Object,
            newStyle: {
                required: false,
                default: false,
                type: Boolean
            }
        },


        components: {
            GooglePlacesAutocomplete
        }
        ,
        data: () => ({
            focus: false,
            uniqueId: "",
            county: ['fr', 'gp', 'mq']
        }),
        created() {
            this.uniqueId = this._uid;
        }
        ,
        computed: {
            ...
                mapGetters('translator', ['msg', 'currentFormattedLang']),
            /*
            address_components:[{"long_name":"13","short_name":"13","types":["street_number"]},{"long_name":"Hackhofergasse","short_name":"Hackhofergasse","types":["route"]},{"long_name":"Döbling","short_name":"Döbling","types":["sublocality_level_1","sublocality","political"]},{"long_name":"Wien","short_name":"Wien","types":["locality","political"]},{"long_name":"Wien","short_name":"Wien","types":["administrative_area_level_1","political"]},{"long_name":"Österreich","short_name":"AT","types":["country","political"]},{"long_name":"1190","short_name":"1190","types":["postal_code"]}]
             */
        }
        ,
        methods: {
            onInput(v) {
                this.$emit('input', v)
            }
            ,
            onBlur() {
                let $this = this;
                setTimeout(() => $this.focus = false, 500)
            }
            ,
            onAddressSelect(placeDetail) {
                if (placeDetail == null || !Array.isArray(placeDetail["address_components"]))
                    return this.$emit('address', null);

                let location = placeDetail["address_components"].reduce((obj, addr) => {

                    addr["types"].forEach(type => {
                        obj[type] = addr["short_name"];
                        if (type === "route")
                            obj["street_name"] = addr["short_name"];
                    });
                    return obj;
                }, {});

                location["formatted_address"] = placeDetail["formatted_address"];
                return this.$emit('address', location);
            }
            ,

        }
    }
</script>

<style>
    ul.vbga-results {
        position: absolute;
        border: 1px solid #ccc;
        background-color: #fff;
        z-index: 9999;

    }

    ul.vbga-results > li {
        list-style-type: none;
        padding: 5px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
    }

    ul.vbga-results > li:hover {
        background-color: #ccc;
    }

</style>