var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-block position-relative",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"address-auto-comple"},[_c('google-places-autocomplete',{attrs:{"country":_vm.county,"show-list":_vm.focus},on:{"resultChanged":_vm.onAddressSelect,"resultCleared":function($event){return _vm.onAddressSelect(null)}},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var context = ref.context;
var events = ref.events;
var actions = ref.actions;
return _c('div',{},[(_vm.newStyle)?[_c('b-form-input',{staticStyle:{"width":"100%"},attrs:{"value":_vm.value,"type":"search","id":("locationInput_" + _vm.uniqueId),"autocomplete":"no"},on:{"focus":function (){events.inputHasReceivedFocus(); _vm.focus=true},"blur":_vm.onBlur,"input":function (v){context.input = v;_vm.onInput(v); events.inputHasChanged()},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return actions.selectItemFromList($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return actions.shiftResultsSelection($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return actions.unshiftResultsSelection($event)}]}})]:[_c('md-input',{staticStyle:{"width":"100%"},attrs:{"value":_vm.value,"type":"search","id":("locationInput_" + _vm.uniqueId),"autocomplete":"no"},on:{"focus":function (){events.inputHasReceivedFocus(); _vm.focus=true},"blur":_vm.onBlur,"input":function (v){context.input = v;_vm.onInput(v); events.inputHasChanged()},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return actions.selectItemFromList($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return actions.shiftResultsSelection($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return actions.unshiftResultsSelection($event)}]}})]],2)}}])},[_c('div',{staticStyle:{"background-color":"#00acc1"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var place = ref.place;
return _c('span',{},[_vm._v(" "+_vm._s(place.description)+" ")])}}])}),_c('div',{staticStyle:{"background-color":"#00c853"},scopedSlots:_vm._u([{key:"activeItem",fn:function(ref){
var place = ref.place;
return _c('span',{},[_vm._v(" "+_vm._s(place.description)+" ")])}}])})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }