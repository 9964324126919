<template>
    <div class="smsPanel">
        <div class="mt-2">
            <!-- Header: Personal Info -->
            <div class="d-flex">
                <feather-icon icon="SendIcon" size="19"/>
                <h4 class="mb-0 ml-50">
                    {{ msg('Send SMS to customer') }}
                </h4>
            </div>
            <b-row class="mt-1">
                <b-col cols="12" md="12" lg="12">
                    <b-form-group :label="msg('Text message')" label-for="sms-text">
                        <b-textarea rows="10" id="sms-text" v-model="sms.message"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: 'smsPanel',
        components: {},
        data() {
            return {
                loaded: false,
                error: null,
                sms: {
                    message: '',
                    clientId: 0
                },
                //Listeners
                listeners: {
                    submit: () => {
                    }
                }
            }
        },
        props: {
            clientId: {
                type: Number,
                required: true,
            }
        },
        created() {
            this.$root.$on('crm::submitSms', (this.listeners.submit = () => {
                console.log("created,rootOn")
                this.submit()
            }))
        },
        beforeDestroy() {
            this.$root.$off('crm::submitSms', this.listeners.submit)
        },
        methods: {
            ...mapActions('clients', {_sendSms: 'sendSms'}),


            sendSms() {
                let $this = this
                console.log("-------send sms")
                this.sms.clientId = this.clientId;
                this._sendSms(this.sms)
                    .then(
                        (id) => {

                            $this.$emit('submitted', {
                                status: true,
                                server: id,
                                id

                            })
                        },
                        (a) => $this.$emit('submitted', {
                            status: false,
                            server: a
                        })
                    )

            },

            submit() {
                console.log("------submit")
                return this.sendSms()
            }

        },

    }
</script>


