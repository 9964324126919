<template>
  <div id="note">
    <div v-if="loaded">
      <div role="tablist">
        <!--TODO:-->
        <template v-if="notes != null && Object.keys(notes).length > 1">
          <b-tabs class="tabs-title m-0 p-0" pills vertical :nav-wrapper-class="`w-20 text-left ${(hasTypes == true ? '' : 'd-none')}`" @input="onTabChange">
            <b-tab v-for="(n,name) in notes" v-bind:key="name" :title="msg(getTypeName(name))" class="p-0">
              <note-console v-model="newNote" v-bind:notes="n" @loadNote="loadNote" @resetNewNote="resetNewNote" @save="save"/>
            </b-tab>
          </b-tabs>
        </template>
        <template v-else-if="notes != null && Object.keys(notes).length === 1">
          <note-console v-model="newNote" v-bind:notes="(Object.values(notes)[0])" @delNote="deleNote" @loadNote="loadNote" @resetNewNote="resetNewNote" @save="save"/>
        </template>
        <template v-else>
          <b-alert show variant="warning" class="text-center">
            <i class="fas fa-exclamation-triangle mr-3"></i>
            <strong>{{ msg('No Note Type set') }}</strong>
            <br/>
          </b-alert>
        </template>

      </div>

    </div>
    <div v-else-if="error != ''">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="text-align: center;height: 100%;padding: 30% 20px 20px;">
        <b-spinner label="Spinning"/>
        {{ msg('loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SpeechToText from '../../widgets/speechToText/SpeechToText'
import NoteConsole from '@/components/panels/notes/noteConsole'

export default {
  name: 'notes',
  // eslint-disable-next-line vue/no-unused-components
  components: { NoteConsole, SpeechToText },

  props: {
    autoload: {
      default: false,
      type: Boolean
    },
    clientId: Number,
    clientName: String,
    historyMaxHeight: {
      default: 600,
      type: Number
    },
    defaultTitle: {
      required: false,
      default: null,
      type: String
    },
  },
  watch: {
    clientId() {
      this.reset()
      if (this.autoload == true) {
        this.loadData()
      }
    },
    notes() {
      setTimeout(this.notesToBottom, 500)
    }
  },
  data: () => ({
    loaded: false,
    error: '',
    newNote: {
      id: 0,
      type: 1,
      typeName: '',
      title: '',
      text: '',
      isPublic: true,
      allPermissionUser: []
    },
    notes: {},
    //Listeners
    listeners: {
      load: () => {
      }
    }
  }),
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('notes', ['hasTypes', 'getTypes', 'getTypeName', 'getTypeDefaultIsPublic']),

    uniqueNotes() {
      let notes = Object.values(this.notes)
      if (notes == null || notes.length == 0) {
        return []
      }
      return notes[0]
    },

  },
  created() {
    // this.loadData();
    this.$root.$on('crm::client::note::load', (this.listeners.load = () => {
      //TODO: CREATE REFRESH TYPE ON TAB CLICK
      if (this.loaded !== true) {
        this.reset()
      }
      this.loadData()
    }))
  },

  beforeDestroy() {
    this.$root.$off('crm::client::note::load', this.listeners.load)
  },
  methods: {
    ...mapActions('notes', ['getNotes', 'get', 'update', 'register', 'deleteNote']),
    speechEnd({ sentences, text }) {
      console.log('text', text)
      console.log('sentences', sentences)
      this.newNote.text = sentences
    },
    reset() {
      this.loaded = false
      this.error = ''

      this.notes = {}
      this.resetNewNote()

    },
    resetNewNote() {
      this.newNote = {
        id: 0,
        type: this.newNote.type,
        typeName: '',
        title: '',
        text: '',
        isPublic: this.newNote.isPublic,
        clientId: this.clientId,
        allPermissionUser: []
      }
    },
    loadData() {
      let $this = this
      this.$emit('update-start', true)
      this.getNotes({
        amountPerPage: -1,
        filter: { clientId: this.clientId }
      })
          .then(
              (noteData) => {
                let notes = noteData.notes
                Object.keys($this.getTypes)
                    .forEach(t => {
                      $this.notes[t] = notes.filter(n => n.type == t)
                    })
                $this.loaded = true
                //Reactivity in Depth
                $this.notes = { ...$this.notes }
                $this.$emit('update-finish', false)
              },
              (e) => {
                $this.error = e
                $this.$emit('update-finish', false)
              }
          )
    },
    onTabChange(tabIndex) {
      //Because we know that the tabs are generating by the type names, we will get the type from the tabs
      this.newNote.type = Object.keys(this.getTypes)[tabIndex]
      this.newNote.isPublic = this.getTypeDefaultIsPublic(this.newNote.type)
      this.resetNewNote()
      setTimeout(this.notesToBottom, 500)
    },
    notesToBottom() {
      /* var container = this.$el.querySelector(".note-history");
       container.scrollTop = container.scrollHeight;*/
      let $ = window.$
      $('.note-history')
          .each(function () {
            $(this)
                .scrollTop(this.scrollHeight)
          })
    },
    loadNote(id) {
      if (id === this.newNote.id) {
        return
      }
      if(id < 0)
      {
        this.deleNote();
      }
      if (id === 0) {
        this.resetNewNote()
        return
      }
      this.get({ id })
          .then((n) => {
            this.newNote = n
          })
    },
    deleNote() {
      console.log('removeNote')
      this.deleteNote(this.newNote.id)
          .then(this.onNoteRemoved)
    },
    onNoteRemoved() {
      this.resetNewNote()
      this.loadData()
    },
    save() {
      this.$root.$emit('bv::hide::popover')
      let $this = this
      if (this.newNote.id <= 0) {
        this.register({ note: this.newNote })
            .then(() => {
              $this.loadData()
              $this.resetNewNote()
            })
      } else {
        this.update({
          id: this.newNote.id,
          note: this.newNote
        })
            .then(
                () => {
                  let i = $this.notes[$this.newNote.type].findIndex(n => n.id == $this.newNote.id)
                  if (i != -1) {
                    $this.notes[$this.newNote.type][i] = $this.newNote
                  }
                  $this.loadData()
                  $this.resetNewNote()
                },
                (e) => {
                })
      }
    },

  }
}
</script>

<style>
#note {
  min-height: 500px;
}

#note .note .note-detail .note-title {
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin: 0;
  font-size: 12px;
  padding: 5px 0;
}

#note .note .note-detail .note-title .note-user {
  text-align: right;
}

#note .note .note-detail .note-title > div {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

#note .note .note-detail .note-text {
  width: 100%;
  margin: 0;
  font-size: 14px;
  padding: 0 7px;

}

#note .note .note-detail .note-time {
  text-align: right;
  font-size: 10px;
  opacity: 0.8;
}

.dark-layout .chat-app-form {
  background-color: rgba(249, 249, 249, 0.09);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
