<template>
    <span class="speach-to-text">
        <md-button  class="md-icon-button  md-theme-default"  :class="{'animated infinite pulse': toggle,'md-xmini':(mini === true)}"   @click.stop="toggle ? endSpeechRecognition() : startSpeechRecognition()">
            <md-icon>
                <i :style="[!toggle ? {color:'grey'} : {color:(speaking ? 'red' : 'blue')}]" class="fas fa-microphone-alt"></i>
            </md-icon>
        </md-button>

    </span>
</template>

<script>
    import {mapGetters} from "vuex";



    export default {
        name: "SpeechToText",
        props: {
            lang: {
                type: String,
                default: null,
            },
            text: {
                required: true
            },
            mini:{
                type: Boolean,
                required: false,
                default:false,
            }
        },
        data () {
            return {
                error: false,
                speaking: false,
                toggle: false,
                runtimeTranscription: '',
                sentences: []
            }
        },
        computed:{
            ...mapGetters('translator', ['msg','currentFormattedLang']),
            language(){
                if(this.lang != null)
                    return this.lang;
                return this.currentFormattedLang
            },
            isCompatible(){
                return !this.recognition
            },
            recognition(){
                let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
                return SpeechRecognition ? new SpeechRecognition() : false;
            }
        },
        methods: {
            endSpeechRecognition () {
                this.recognition.stop()
                this.toggle = false
                this.$emit('speechend', {
                    sentences: this.sentences,
                    text: this.sentences.join('. ')
                })
            },
            startSpeechRecognition () {
                if (!this.recognition) {
                    this.error = 'Speech Recognition is not available on this browser. Please use Chrome or Firefox'
                    return false
                }
                this.toggle = true
                this.recognition.lang = this.language
                this.recognition.interimResults = true

                this.recognition.addEventListener('speechstart', event => {
                    this.speaking = true
                })

                this.recognition.addEventListener('speechend', event => {
                    this.speaking = false
                })

                this.recognition.addEventListener('result', event => {
                    const text = Array.from(event.results).map(result => result[0]).map(result => result.transcript).join('')
                    this.runtimeTranscription = text
                })

                this.recognition.addEventListener('end', () => {
                    if (this.runtimeTranscription !== '') {
                        this.sentences.push(this.capitalizeFirstLetter(this.runtimeTranscription))
                        this.$emit('update:text', `${this.text}${this.sentences.slice(-1)[0]}. `)
                    }
                    this.runtimeTranscription = ''
                    this.recognition.stop()
                    if (this.toggle) {
                        // keep it going.
                        this.recognition.start()
                    }
                })
                this.recognition.start()
            },
            capitalizeFirstLetter (string) {
                return string.charAt(0).toUpperCase() + string.slice(1)
            }
        },
    }
</script>

<style scoped>

</style>
