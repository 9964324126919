<template>
<div>
  <!-- Body -->
  <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
  >
    <!-- Form -->
    <div class="p-2">
      <!-- Title -->
      <validation-provider #default="{ errors }" name="Title" rules="required">
        <b-form-group v-bind:label="msg('Title')" label-for="event-title">
          <b-form-input
              id="event-title"
              v-model="eventLocal.title"
              autofocus
              :state="errors.length > 0 ? false:null"
              trim
          />

          <b-form-invalid-feedback>
           {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Calendar -->
      <validation-provider
          #default="{ errors }"
          name="Calendar"
          rules="required"
      >
        <b-form-group
            v-bind:label="msg('Calendar')"
            label-for="calendar"
            :state="errors.length > 0 ? false:null"
        >
          <v-select
              v-model="eventLocal.type"
              :dir="isRTL ? 'rtl' : 'ltr'"
              :options="Object.values(types)"
              label="name"
              :reduce="type => type.id"
          >
            <template #option="{ color, name }">
              <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="`bg-${color}`"
                  style="height:10px;width:10px"
              />
              <span> {{ name }}</span>
            </template>

            <template #selected-option="{ color, name }">
              <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="`bg-${color}`"
                  style="height:10px;width:10px"
              />
              <span> {{ name }}</span>
            </template>
          </v-select>

          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
           {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-row no-gutters>
        <b-col cols="6">
          <!-- Start Date -->
          <validation-provider
              #default="{ errors }"
              name="Start Date"
              rules="required"
          >

            <b-form-group
                v-bind:label="msg('Start Date')"
                label-for="start-date"
                :state="errors.length > 0 ? false:null"
            >
              <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="1"></b-col>
        <b-col cols="5">
          <!-- End Date -->
          <validation-provider
              #default="{ errors }"
              name="End Date"
              rules="required"
          >

            <b-form-group
                v-bind:label="msg('End Date')"
                label-for="end-date"
                :state="errors.length > 0 ? false:null"
            >
              <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>


      <!-- Users -->
      <b-form-group
          v-bind:label="msg('Representatives')"
          label-for="add-guests"
      >
        <v-select
            v-model="eventLocal.usersIds"
            :dir="isRTL ? 'rtl' : 'ltr'"
            multiple
            :close-on-select="false"
            :options="otherUsers"
            label="name"
            :reduce="(us) => us.id"
        >

          <template #option="{ avatar, name }">
            <b-avatar
                size="sm"
                :src="avatar"
            />
            <span class="ml-50 align-middle"> {{ name }}</span>
          </template>

          <template #selected-option="{ avatar, name }">
            <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
            />
            <span class="ml-50 align-middle"> {{ name }}</span>
          </template>
        </v-select>
      </b-form-group>

      <!-- Location -->
      <b-form-group
          v-bind:label="msg('Location')"
          label-for="event-location"
      >
        <b-form-input
            id="event-location"
            v-model="eventLocal.location"
            trim
        />
      </b-form-group>

      <!-- Textarea -->
      <b-form-group
          v-bind:label="msg('Description')"
          label-for="event-description"
      >
        <b-form-textarea
            id="event-description"
            v-model="eventLocal.body"
        />
      </b-form-group>

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="invalid"
            @click="registerEvent"
        >
          {{ msg('Add') }}
        </b-button>
      </div>
    </div>
  </validation-observer>
</div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'addClientCalendar',
  components:{flatPickr},
  data:()=>({
    eventLocal:{

    }
  }),
  props:{
    client:{
      type:Object,
      required:true
    }
  },
  watch:{
    client:{
      handler(){
        let branchId = this.client.buid;
        let clientsIds = [this.client.id];
        this.eventLocal = {...this.getEmptyEvent(),clientsIds,branchId};
      },
      immediate:true,
    },
  },
  computed:{
    ...mapGetters('calendar', { types: 'getTypes' }),
    ...mapGetters('data', ['getOtherUsers']),
    otherUsers() {
      return this.getOtherUsers.map(us => ({
        id: us.id,
        name: (us.firstname + ' ' + us.lastname)
      }))
    },
  },
  methods:{
    ...mapActions('calendar', {_registerEvent:'register'}),
    getEmptyEvent(){
      let start = this.$moment(this.defaultDate || Date.now() + 3600*1000).format('YYYY-MM-DD HH:mm');
      let end = this.$moment(start,'YYYY-MM-DD HH:mm').add(1, 'hours').format('YYYY-MM-DD HH:mm');
      return { branchId: 0, clientsIds: [], end: end, start: start, location: null, status: 0, title: null, type: 0, url: null, usersIds: [], body: null }    },
    registerEvent(){
      let $this = this;
      this._registerEvent({event:this.eventLocal}).then(
          ()=>$this.showToast('success','CheckIcon',"Event registered successfully","Calendar"),
          ()=>$this.showToast('danger','Xcon',"Event registration failed","Calendar")
      ).finally(()=>$this.$root.$emit('crm::calendar::refresh'))
    },
  }
}
</script>

<style scoped>

</style>
