var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"p-2"},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.msg('Title'),"label-for":"event-title"}},[_c('b-form-input',{attrs:{"id":"event-title","autofocus":"","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.eventLocal.title),callback:function ($$v) {_vm.$set(_vm.eventLocal, "title", $$v)},expression:"eventLocal.title"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Calendar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.msg('Calendar'),"label-for":"calendar","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":Object.values(_vm.types),"label":"name","reduce":function (type) { return type.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var color = ref.color;
var name = ref.name;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var color = ref.color;
var name = ref.name;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.eventLocal.type),callback:function ($$v) {_vm.$set(_vm.eventLocal, "type", $$v)},expression:"eventLocal.type"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.msg('Start Date'),"label-for":"start-date","state":errors.length > 0 ? false:null}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i'}},model:{value:(_vm.eventLocal.start),callback:function ($$v) {_vm.$set(_vm.eventLocal, "start", $$v)},expression:"eventLocal.start"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"1"}}),_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.msg('End Date'),"label-for":"end-date","state":errors.length > 0 ? false:null}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i'}},model:{value:(_vm.eventLocal.end),callback:function ($$v) {_vm.$set(_vm.eventLocal, "end", $$v)},expression:"eventLocal.end"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-form-group',{attrs:{"label":_vm.msg('Representatives'),"label-for":"add-guests"}},[_c('v-select',{attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"multiple":"","close-on-select":false,"options":_vm.otherUsers,"label":"name","reduce":function (us) { return us.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50 align-middle"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50 align-middle"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.eventLocal.usersIds),callback:function ($$v) {_vm.$set(_vm.eventLocal, "usersIds", $$v)},expression:"eventLocal.usersIds"}})],1),_c('b-form-group',{attrs:{"label":_vm.msg('Location'),"label-for":"event-location"}},[_c('b-form-input',{attrs:{"id":"event-location","trim":""},model:{value:(_vm.eventLocal.location),callback:function ($$v) {_vm.$set(_vm.eventLocal, "location", $$v)},expression:"eventLocal.location"}})],1),_c('b-form-group',{attrs:{"label":_vm.msg('Description'),"label-for":"event-description"}},[_c('b-form-textarea',{attrs:{"id":"event-description"},model:{value:(_vm.eventLocal.body),callback:function ($$v) {_vm.$set(_vm.eventLocal, "body", $$v)},expression:"eventLocal.body"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":invalid},on:{"click":_vm.registerEvent}},[_vm._v(" "+_vm._s(_vm.msg('Add'))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }