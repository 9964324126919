<template>
  <div id="accountRepresentative">
    <div v-if="loaded">
      <!-- REPRESENTATIVE TABLE -->
      <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2 w-100">
            <b-row>
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <feather-icon icon="UsersIcon" size="18"/>
                <span class="align-middle ml-50">{{ msg('Representatives') }}</span>
              </b-col>
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <button id="addRepresentative" size="sm" class="btn btn-primary">
                    <feather-icon icon="UserPlusIcon" size="12"/>
                    {{ msg('Add representative') }}
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-card-title>
        </b-card-header>
        <table role="table" aria-busy="false" aria-colcount="5" class="table b-table table-striped">
          <thead role="rowgroup" class="">
          <tr role="row" class="">
            <th role="columnheader" scope="col" aria-colindex="1" class="">
              <div>{{ msg('Name') }}</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="2" class="">
              <div>{{ msg('Role') }}</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="4" class="">
              <div>{{ msg('Start') }}</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="3" class="">
              <div>{{ msg('Ends') }}</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="5" class=""></th>
          </tr>
          </thead>
          <tbody role="rowgroup">
          <!---->
          <tr role="row" v-for="(rep,index) in allRepresentatives" v-bind:key="rep.id" @dblclick="loadRepresentative(index)">
            <td aria-colindex="1" role="cell" class="">
              <div class="d-inline-block">
                <b-avatar size="40" variant="light-primary" badge class="badge-minimal" :badge-variant="rep.end == null || !dateExpired(rep.end) ? 'success' : 'warning'">
                  <feather-icon  icon="UserIcon" size="22"/>
                </b-avatar>
              </div>
              {{ getUserFullName(rep.userid) }}
            </td>
            <td aria-colindex="2" role="cell" class="">{{ getRoleName(rep.roleid) }}</td>
            <td aria-colindex="3" role="cell" class="">{{ rep.start }}</td>
            <td aria-colindex="4" role="cell" class="">{{ rep.end }}</td>
            <td aria-colindex="6" role="cell" class="">
              <b-button variant="gradient-danger" class="btn-icon rounded-circle" size="sm"
                        v-if="rep.end == null || !dateExpired(rep.end)"
                        v-b-tooltip :title="msg('Stop')" @click="stopRepresentativeToAccount(rep)">
                <i class="far fa-hand-paper" style="font-size: 12px"></i>
              </b-button>
            </td>
          </tr>
          </tbody>
          <!---->
        </table>
      </b-card>

      <!--New user popover-->
      <b-popover :target="`addRepresentative`" placement="rightbottom" :title="msg('Add attendant')">
        <template slot="title">
          {{ msg('Add representative') }}
        </template>
        <div class="md-list md-dense">
          <b-row class="mt-1">
            <!-- Field: Name -->
            <b-col cols="12">
              <b-form-group :label="msg('Name')" label-for="rep-user">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="newRepresentative.userid"
                    :options="allUsers"
                    class="w-100"
                    label="name"
                    :reduce="(us) => us.id"
                    :clearable="false"
                    @input="updateNewRepRole"
                />
              </b-form-group>
            </b-col>
            <!-- Field: Role -->
            <b-col cols="12">
              <b-form-group :label="msg('Role')" label-for="rep-role">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="newRepresentative.roleid"
                    :options="allRoles"
                    class="w-100"
                    label="name"
                    :reduce="(role) => role.id"
                    :clearable="false"
                    disabled

                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="msg('Start')" label-for="rep-start">
                <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="newRepresentative.start"
                                      :locale="currentFormattedLang" format="YYYY-MM-DD HH:mm" formatted="ll" no-header auto-close :min-date="dateNow">
                  <b-form-input id="rep-start" v-model="newRepresentative.start"/>
                </VueCtkDateTimePicker>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="msg('Ends')" label-for="rep-end">
                <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="newRepresentative.end"
                                      :locale="currentFormattedLang" format="YYYY-MM-DD HH:mm" formatted="ll" no-header auto-close :min-date="dateNow">
                  <b-form-input id="rep-end" v-model="newRepresentative.end"/>
                </VueCtkDateTimePicker>
              </b-form-group>
            </b-col>
          </b-row>
          <!--  actions-->
          <div>
            <b-button variant="warning" @click="dismissPopover">
              <feather-icon icon="XIcon" class="mr-50"/>
              <span class="align-middle">{{ msg('Cancel') }}</span>
            </b-button>
            <span class="m-1"></span>
            <b-button variant="success" @click="save">
              <feather-icon icon="SaveIcon" class="mr-50"/>
              <span class="align-middle">{{ msg('Save') }}</span>
            </b-button>
          </div>
        </div>
      </b-popover>
    </div>
    <div v-else-if="this.error != ''">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <b-spinner label="Spinning"/>
      {{ msg('loading') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { date } from '../../../global/helper'

export default {
  name: 'representatives',
  props: {
    clientId: Number,
    representatives: Array,
    canAdd: {
      default: true,
      type: Boolean
    },
    historyMaxHeight: {
      default: 500,
      type: Number
    }
  },
  watch: {
    clientId() {
      this.reset()
      this.loadData()//Debug
    },
  },
  data: () => ({
    loaded: false,
    error: '',
    newRepresentative: {
      clientId: 0,
      end: '',
      id: 0,
      roleid: 0,
      start: date.nowToString(),
      userid: 0,
    },
    //Listeners
    listeners: {
      load: () => {
      }
    }
  }),
  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    ...mapGetters('data', ['getUserFullName', 'getUsersByRole', 'getAllUsers', 'getUser']),
    ...mapGetters('data', ['getRoleName', 'getRoles']),
    allRoles() {
      return Object.values(this.getRoles)
    },
    canSave() {
      return !isNaN(this.newRepresentative.roleid) && this.newRepresentative.roleid > 0 && !isNaN(this.newRepresentative.userid) && this.newRepresentative.userid > 0
    },
    allRepresentatives() {
      if (this.clientId == null || isNaN(this.clientId) || this.clientId <= 0) {
        return []
      }

      if (this.representatives == null || !Array.isArray(this.representatives)) {
        return []
      }

      return this.representatives
    },
    allUsers() {
      if (this.newRepresentative.id < 0) {
        return this.getUsersByRole(this.newRepresentative.roleid)
      }

      return this.getAllUsers.map(us => ({
        id: us.id,
        name: (us.firstname + ' ' + us.lastname)
      }))
    },
    dateNow() {
      return date.nowToString()
    }
  },
  created() {
    this.loadData()//Debug
    this.$root.$on('crm::clientId::representative::load', (this.listeners.load = () => {
      this.reset()
      this.loadData()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::clientId::representative::load', this.listeners.load)
  },
  methods: {
    ...mapActions('clients', ['setRepresentativeToAccount']),
    ...mapActions('clients', { _stopRepresentativeToAccount: 'stopRepresentativeToAccount' }),

    reset() {
      this.loaded = false
      this.error = ''

      this.resetNewRepresentative()

    },
    resetNewRepresentative() {
      this.newRepresentative = {
        clientId: this.clientId,
        end: null,
        id: 0,
        roleid: 0,
        start: date.nowToString(),
        userid: 0,
      }
    },
    dismissPopover() {
      this.$root.$emit('bv::hide::popover')
    },
    updateNewRepRole() {
      let $this = this
      setTimeout(() => {
        let user = this.getUser($this.newRepresentative.userid)
        if (user == null || user.id == null) {
          $this.newRepresentative.roleid = 0
          return
        }
        $this.newRepresentative.roleid = user.roleId
      }, 200)

    },

    loadData() {
      let $this = this
      if (this.clientId == null || isNaN(this.clientId) || this.clientId <= 0) {
        return $this.error = 'Invalid clientId'
      }

      /*if (this.representatives == null || !Array.isArray(this.representatives))
          return $this.error = "Invalid clientId representatives";*/

      $this.loaded = true

    },
    loadRepresentative(index) {

      let rep = this.allRepresentatives[index]
      if (rep.id == this.newRepresentative.id) {
        return
      }
      //Check if already expired
      if (rep.end != null && rep.end != '' && (new Date(rep.end)).getTime() < Date.now()) {
        return
      }
      this.newRepresentative = { ...rep }
    },
    dateExpired(dateStr){
      return dateStr != null && dateStr.trim() !== '' && (new Date(dateStr)).getTime() < Date.now()
    },
    save() {
      let $this = this
      if (isNaN(Date.parse(this.newRepresentative.start))) {
        this.newRepresentative.start = null
      }
      if (isNaN(Date.parse(this.newRepresentative.end))) {
        this.newRepresentative.end = null
      }

      if (this.newRepresentative.clientId != this.clientId) {
        this.newRepresentative.clientId = this.clientId
      }

      this.setRepresentativeToAccount({ rep: this.newRepresentative })
          .then(function (id) {
            //In case that was new
            if (isNaN($this.newRepresentative.id) || $this.newRepresentative.id <= 0) {
              $this.newRepresentative.id = id
              $this.representatives.push($this.newRepresentative)
            } else { // in case of other
              $this.allRepresentatives.forEach(r => {
                if (r.id === $this.newRepresentative.id) {
                  r.start = $this.newRepresentative.start
                  r.end = $this.newRepresentative.end
                }
              })
            }
          })
          .finally(() => {
            $this.resetNewRepresentative()
            $this.dismissPopover()
          })
    },
    stopRepresentativeToAccount(rep) {
      let $this = this
      this._stopRepresentativeToAccount(rep)
          .then(() => {
            $this.$emit('repUpdated')
            rep.end = $this.$moment(Date.now())
                .format('YYYY-MM-DD HH:mm')
            $this.$forceUpdate();
          })
    }
  }
}
</script>

<style scoped>

</style>
