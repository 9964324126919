<template>
  <div id="clientsDetails" class="clients-details">
    <div v-if="loaded">
      <template v-if="showOrganization === true">
        <!-- Header: Administrative Info -->
        <div class="d-flex mt-1">
          <feather-icon icon="ArchiveIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('Administrative') }}
          </h4>
        </div>

        <!-- Form: Administrative Form -->
        <b-row class="mt-1">
          <!-- Field: branch -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Branch')" label-for="client-branch">
              <v-select
                  v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="client.branchId"
                  :options="allBranches"
                  class="w-100"
                  label="name"
                  :reduce="(branch) => branch.id"
                  :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Creation Date -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Creation Date')" label-for="client-creationTime">
              <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="client.creationTime"
                                    :locale="currentFormattedLang" format="YYYY-MM-DD HH:mm" formatted="ll" no-header
                                    auto-close>
                <b-form-input id="client-creationTime" v-model="client.creationTime" disabled/>
              </VueCtkDateTimePicker>

            </b-form-group>
          </b-col>

          <!-- Field: Registration date -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('registration date')" label-for="client-registration-date">
              <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="client.interesttime"
                                    :locale="currentFormattedLang" format="YYYY-MM-DD HH:mm" formatted="ll" no-header
                                    auto-close>
                <b-form-input id="client-registrationdate" v-model="client.interesttime" disabled/>
              </VueCtkDateTimePicker>

            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Status')" label-for="client-branch">
              <v-select
                  v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="client.statusid"
                  :options="allStatus"
                  class="w-100"
                  label="status"
                  :reduce="(status) => status.id"
                  :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Renter')" label-for="client-branch" :description="msg('Set client as renter')">
              <b-form-checkbox v-model="client.clientExt.renter" name="check-button" switch>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Owner')" label-for="client-branch" :description="msg('Set client as owner') ">
              <b-form-checkbox v-model="client.clientExt.owner" name="check-button" switch>
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Verification')" label-for="client-branch"
                          :description="msg('Set client as verified') ">
              <b-form-checkbox v-model="client.clientExt.verified" name="check-button" switch>
              </b-form-checkbox>
            </b-form-group>
          </b-col>

        </b-row>

        <!--TODO: Recreate affiliate-->
        <!-- Header: Affiliate Info -->
        <div class="d-flex mt-1">
          <i class="far fa-handshake" style="font-size: 19px"></i>
          <h4 class="mb-0 ml-50">
            {{ msg('Affiliate') }}
          </h4>
        </div>

        <!-- Form: Affiliate Form-->
        <b-row class="mt-1">
          <!-- Field: Affiliate -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Affiliate')" label-for="client-affId">
              <v-select
                  v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="client.affId"
                  :options="allAffiliates"
                  class="w-100"
                  label="company"
                  :reduce="(aff) => aff.id"
                  :clearable="false"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Campaign -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Campaign')" label-for="client-camId">
              <v-select
                  v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="client.camId"
                  :options="clientCampaign"
                  class="w-100"
                  label="name"
                  :reduce="(cam) => cam.id"
                  :clearable="false"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Banner -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Banner')" label-for="client-bannerid">
              <v-select
                  v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="client.bannerid"
                  :options="clientBanner"
                  class="w-100"
                  label="name"
                  :reduce="(cam) => cam.id"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Tag -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Tag')" label-for="client-tag">
              <b-form-input id="client-tag" v-model="client.tag"/>
            </b-form-group>
          </b-col>

        </b-row>
      </template>

      <template v-if="showClient === true">
        <validation-observer ref="refFormObserver">
          <div id="clientForm" ref="clientForm" @submit.prevent="submit">

            <!-- Header: Personal Info -->
            <div class="d-flex">
              <feather-icon
                  icon="UserIcon"
                  size="19"
              />
              <h4 class="mb-0 ml-50">
                {{ msg('Personal Information') }}
              </h4>
            </div>

            <!-- Form: Personal Info Form -->
            <b-row class="mt-1">
              <!-- Field: First name -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('First name')" label-for="client-firstname">
                  <validation-provider #default="{ errors }" :name="msg('First name')" rules="required">
                    <b-form-input id="client-firstname" v-model="client.firstname"
                                  :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Last name -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Last name')" label-for="client-lastname">
                  <b-form-input id="client-lastname" v-model="client.lastname"/>
                </b-form-group>
              </b-col>

              <!-- Field: Language -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Language')" label-for="client-language">
                  <v-select
                      v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="client.lang"
                      :options="allLanguage"
                      class="w-100"
                      label="language"
                      :reduce="(lang) => lang.code"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Document type -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Document type')" label-for="client-docType">
                  <v-select
                      v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="client.docType"
                      :options="[{value: 'id',label:msg('Document Id')},{value: 'passport',label:msg('Passport')}]"
                      class="w-100"
                      :reduce="(type) => type.value"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Document number -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Document number')" label-for="client-docNum">
                  <b-form-input id="client-docNum" v-model="client.docNum"/>
                </b-form-group>
              </b-col>

              <!-- Field: Birth Date -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('birthday')" label-for="client-birthday">
                  <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="client.birthday"
                                        :locale="currentFormattedLang" :only-date="true" format="DD-MM-YYYY"
                                        formatted="ll" no-header auto-close>
                    <b-form-input id="client-birthday" v-model="client.birthday"/>
                  </VueCtkDateTimePicker>

                </b-form-group>
              </b-col>

              <!-- Field: Next appointment -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Next appointment')" label-for="client-password" class="w-100">
                  <div role="combobox" class="vs__dropdown-toggle" style="padding: 6px 0 6px 12px;align-items: center;">
                    <div class="vs__selected-options">
                        <span v-if="appointments.length > 0" :class="{['text-'+appointments[0].color]:true}">
                          <feather-icon icon="CalendarIcon" size="15" style="vertical-align: text-top"/>
                          {{ appointments[0].start | moment('DD/MM/YY HH:mm') }} - {{ appointments[0].title }}
                        </span>
                    </div>
                    <div class="vs__actions align-items: center;" style="margin-top:-5px ">
                      <button id="popover_cal_info" type="button" v-if="appointments.length > 0" class="vs__clear">
                        <feather-icon icon="InfoIcon" size="15"/>
                      </button>
                      <b-popover target="popover_cal_info" v-if="appointments.length > 0"
                                 @show=" $root.$emit('bv::hide::popover');"
                                 triggers="click focus" size="xl"
                                 style="max-width: none">
                        <template slot="title">
                          <b-row>
                            <b-col cols="9">
                              {{ appointments[0].title }}
                            </b-col>
                            <b-col cols="3">
                              <div class="d-flex align-items-center justify-content-end">
                                <b-link style="color: inherit;" @click="$root.$emit('bv::hide::popover')"
                                        :title="msg('Close')">
                                  <feather-icon icon="XIcon"/>
                                </b-link>
                              </div>
                            </b-col>
                          </b-row>
                        </template>
                        <div style="width: 450px" :temp="'ev-'+appointments[0].id">
                          <div class="event-popover-content">
                            <calendarEvent :event="appointments[0]"/>
                          </div>
                        </div>
                      </b-popover>
                      <!-- Add-->
                      <div class="d-inline-block">
                        <button id="addEvent" type="button" class="vs__clear">
                          <feather-icon icon="PlusIcon" size="15"/>
                        </button>
                        <b-popover target="addEvent"
                                   :placement="isRTL ? 'left' : 'right'"
                                   @show=" $root.$emit('bv::hide::popover');"
                                   triggers="click focus" size="xl"
                                   style="max-width: none">
                          <template slot="title">
                            <b-row>
                              <b-col cols="9">
                                {{ msg("New appointment") }}
                              </b-col>
                              <b-col cols="3">
                                <div class="d-flex align-items-center justify-content-end">
                                  <b-link style="color: inherit;" @click="$root.$emit('bv::hide::popover')"
                                          :title="msg('Close')">
                                    <feather-icon icon="XIcon"/>
                                  </b-link>
                                </div>
                              </b-col>
                            </b-row>
                          </template>
                          <div style="width: 450px">
                            <add-client-calendar :client="client"/>
                          </div>
                        </b-popover>
                      </div>
                      <!--Dropdown-->
                      <b-dropdown menu-class="xl" variant="link" toggle-class="text-decoration-none p-0 m-0" no-caret>
                        <template #button-content>
                          <button type="button" :title="msg('Generate')" class="vs__clear m-0">
                            <feather-icon icon="ChevronDownIcon" size="15"/>
                          </button>
                        </template>
                        <div class="p-1" style="min-width: 300px">
                          <span class="text-secondary mb-2" style="white-space: nowrap">{{ msg('Appointments') }}</span>
                          <b-row no-gutters class="text-justify p-0 m-0" href="#" v-for="appointment in appointments"
                                 v-bind:key="appointment.id">
                            <b-col cols="11"
                                   style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;max-width: 200px"
                                   :class="{['text-'+appointment.color]:true}">
                              <feather-icon icon="CalendarIcon" size="15" style="vertical-align: text-top"/>
                              {{ appointment.start | moment('DD/MM/YY HH:mm') }} - {{
                                appointment.title
                              }}
                            </b-col>
                            <b-col cols="1" style="overflow: visible">
                              <div class="d-flex align-items-center justify-content-end">
                                <button :id="'popover_cal_info'+appointment.id" type="button" :title="msg('Generate')"
                                        class="vs__clear" @click="generatePassword">
                                  <feather-icon icon="InfoIcon" size="15"/>
                                </button>
                              </div>
                              <b-popover :target="'popover_cal_info'+appointment.id"
                                         :placement="isRTL ? 'left' : 'right'"
                                         @show=" $root.$emit('bv::hide::popover');"
                                         triggers="click focus" size="xl"
                                         style="max-width: none">
                                <template slot="title">
                                  <b-row>
                                    <b-col cols="9">
                                      {{ appointment.title }}
                                    </b-col>
                                    <b-col cols="3">
                                      <div class="d-flex align-items-center justify-content-end">
                                        <b-link style="color: inherit;" @click="$root.$emit('bv::hide::popover')"
                                                :title="msg('Close')">
                                          <feather-icon icon="XIcon"/>
                                        </b-link>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </template>
                                <div style="width: 450px" :temp="'ev-'+appointment.id">
                                  <div class="event-popover-content">
                                    <calendarEvent :event="appointment"/>
                                  </div>
                                </div>
                              </b-popover>
                            </b-col>
                          </b-row>
                        </div>
                      </b-dropdown>

                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Company')" label-for="client-company">
                  <b-form-input id="client-docNum" v-model="client.clientExt.ownCompany"/>
                </b-form-group>
              </b-col>
              <!-- Field: Gender -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Gender')" label-for="client-gender" label-class="mb-1">
                  <b-form-radio-group
                      id="client-gender"
                      v-model="client.gender"
                      :options="[ { text: msg('Male'), value: 'm' }, { text: msg('Female'), value: 'f' },{ text: msg('Diverse'), value: 'd' } ]"
                      value="male"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <!-- Header: Contact Info -->
            <div class="d-flex mt-1">
              <feather-icon icon="PhoneIcon" size="19"/>
              <h4 class="mb-0 ml-50">
                {{ msg('Contact') }}
              </h4>
            </div>

            <!-- Form: Personal Contact Form -->
            <b-row class="mt-1">
              <!-- Field: Phone -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Phone')" label-for="client-phone">
                  <validation-provider #default="{ errors }" vid="phone" :name="msg('Phone')" rules="required">
                    <b-form-input id="client-phone" v-model="client.phone" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              <b-col>
                <b-form-group :label="msg('Mobile')" label-for="client-mobile">

                  <b-input-group>
                    <b-form-input id="client-mobile" v-model="client.mobile"/>
                    <b-input-group-append>
                      <b-button variant="outline-primary" v-b-tooltip :title="msg('Send SMS to client')"
                                @click="openSmsDialog">
                        SMS
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Field: Email -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Email')" label-for="client-email">
                  <validation-provider #default="{ errors }" :name="msg('Email')" rules="required|email">
                    <b-form-input id="client-email" v-model="client.email" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <!-- Header: Personal Info -->
            <div class="d-flex mt-1">
              <feather-icon
                  icon="MapPinIcon"
                  size="19"
              />
              <h4 class="mb-0 ml-50">
                {{ msg('Address') }}
              </h4>
            </div>

            <!-- Form: Personal Info Form -->
            <b-row class="mt-1">

              <!-- Field: Address-->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Address')" label-for="client-address">
                  <b-form-input id="client-address" v-model="client.address"/>
                </b-form-group>
              </b-col>

              <!-- Field: Postcode (Zip) -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Postcode')" label-for="postcode">
                  <b-form-input id="postcode" v-model="client.zip" type="number"/>
                </b-form-group>
              </b-col>

              <!-- Field: City -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group label="City" label-for="client-city">
                  <b-form-input id="client-city" v-model="client.city"/>
                </b-form-group>
              </b-col>


              <!-- Field: Country -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Country')" label-for="client-country">
                  <v-select
                      v-bind:dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="client.country"
                      :options="allCountries"
                      class="w-100"
                      label="name"
                      :reduce="(lang) => lang.iso"
                      autocomplete="on"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Header: Web access Info -->
            <div class="d-flex mt-1">
              <feather-icon
                  icon="AtSignIcon"
                  size="19"
              />
              <h4 class="mb-0 ml-50">
                {{ msg('Web Access') }}
              </h4>
            </div>

            <!-- Form: Web access Form -->
            <b-form class="mt-1">
              <b-row>

                <!-- Field: External id -->
                <b-col cols="12" md="6" lg="4">
                  <b-form-group :label="msg('External ID')" label-for="client-externalid">
                    <b-form-input id="client-externalid" v-model="client.externalid" disabled/>
                  </b-form-group>
                </b-col>

                <!-- Field: username -->
                <b-col cols="12" md="6" lg="4">
                  <b-form-group :label="msg('Username')" label-for="client-username">
                    <b-form-input id="client-username" v-model="client.username"/>
                  </b-form-group>
                </b-col>

                <!-- Field: password -->
                <b-col cols="12" md="6" lg="4">
                  <div class="d-flex">
                    <b-form-group :label="msg('New password')" label-for="client-password" class="w-100">
                      <div role="combobox" class="vs__dropdown-toggle">
                        <div class="vs__selected-options">
                          <input id="client-password" type="search" autocomplete="off" class="vs__search"
                                 v-model="client.password">
                        </div>
                        <div class="vs__actions">
                          <button type="button" :title="msg('Generate')" class="vs__clear" @click="generatePassword">
                            <feather-icon icon="RotateCwIcon" size="19"/>
                          </button>
                        </div>
                      </div>
                    </b-form-group>
                  </div>

                </b-col>

              </b-row>

            </b-form>

            <!-- Header: Web access Info -->
            <div class="d-flex mt-1">
              <i class="far fa-comment-dots" style="font-size: 19px"></i>
              <h4 class="mb-0 ml-50">
                {{ msg('Comment') }}
              </h4>
            </div>
            <b-row>
              <!-- Field: Address-->
              <b-col cols="12 mt-1">
                <b-form-group label-for="client-address">
                  <b-form-input id="client-address" v-model="client.comment"/>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </validation-observer>
      </template>

      <template v-if="showBank === true">
        <div id="clientBankForm" ref="clientForm" @submit.prevent="submit">

          <!-- Header: Personal Info -->
          <div class="d-flex">
            <feather-icon
                icon="CreditCardIcon"
                size="19"
            />
            <h4 class="mb-0 ml-50">
              {{ msg('Bank information') }}
            </h4>
          </div>

          <!-- Form: Personal Info Form -->
          <b-row class="mt-1">
            <!-- Field: First name -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group :label="msg('Account name')" label-for="client-accountName">
                <b-form-input id="client-firstname" v-model="client.clientExt.bankName"/>
              </b-form-group>
            </b-col>

            <!-- Field: Last name -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group :label="msg('Iban')" label-for="client-iban">
                <b-form-input id="client-lastname" v-model="client.clientExt.iban"/>
              </b-form-group>
            </b-col>


            <!-- Field: Document number -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group :label="msg('Bic')" label-for="client-bic">
                <b-form-input id="client-docNum" v-model="client.clientExt.bic"/>
              </b-form-group>
            </b-col>


          </b-row>


        </div>
      </template>

      <template v-if="showBookings === true">
        <div v-if="bookings && bookings.length > 0">
          <b-row>
            <b-col v-for="booking in bookings" :key="booking._id" cols="12" md="3" lg="3">
              <b-card class="mb-3" border-variant="primary" header-tag="header">
                <template #header>
                  <h5>{{ booking.propertyAddress }}</h5>
                  <small class="text-muted">{{ formatDate(booking.creationTime) }}</small>
                </template>

                <b-card-text>
                  <strong>{{msg('Client')}}: </strong> {{ booking.client.firstname }} {{ booking.client.lastname }}<br>
                  <strong>{{msg('Email')}}:</strong> {{ booking.client.email }}<br>
                  <strong>{{msg('Phone')}}:</strong> {{ booking.client.phone }}<br>
                  <strong>{{msg('Number of guests')}}:</strong> {{ booking.numberOfGuests }}<br>
                  <strong>{{msg('Status')}}:</strong> <b-badge :variant="statusVariant[booking.status]">{{ booking.status }}</b-badge><br>
                  <strong>{{msg('Check-in')}}:</strong> {{ $moment(booking.bnbStyleRent.start).format("DD.MM.YYYY") }}<br>
                  <strong>{{msg('Check-out')}}:</strong> {{ $moment(booking.bnbStyleRent.end).format("DD.MM.YYYY") }}<br>
                  <strong>{{msg('Total Price')}}:</strong> {{ convertToEURO(booking.bnbStyleRent.totalPayment) }}<br>
                  <strong>{{msg('Cleaning Fee')}}:</strong> {{ convertToEURO(booking.bnbStyleRent.cleaningFee) }}<br><br><br>
                  <strong>ID:</strong> {{ booking.id }}<br>

                </b-card-text>

                <b-button variant="primary" @click="toTheContract(booking.id)">{{msg('To the contract')}}</b-button>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <p>No bookings available at the moment.</p>
        </div>
      </template>

    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <b-spinner label="Spinning"/>
      {{ msg('loading') }}
    </div>
    <b-modal no-fade v-model="dialogs.sms" size="l" ref="sms-modal" id="sms-modal" :hide-footer="true"
             no-close-on-backdrop centered>
      <sms-panel ref="smsPanel" @submitted="onSubmitted" :client-id="client.id"/>
      <div class="mt-2 text-center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitSMS">
          {{ msg('Send SMS') }}
        </b-button>
      </div>
    </b-modal>
  </div>

</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import addressAutoComplete from '../../widgets/google/addressAutoComplete'
import calendarEvent from '@/components/widgets/calendar/calendarEvent'
import addClientCalendar from '@/components/widgets/calendar/addClientCalendar'
import SmsPanel from '@/components/panels/client/smsPanel'

export default {
  name: 'clientDetails',
  // eslint-disable-next-line vue/no-unused-components
  components: {addressAutoComplete, calendarEvent, addClientCalendar, SmsPanel},
  props: {
    value: Object,
    appointments: Array,
    showClient: {
      default: true,
      type: Boolean
    },
    showBank: {
      default: false,
      type: Boolean
    },
    showBookings: {
      default: false,
      type: Boolean
    },
    bookings: {
      type: Array,
      required: false
    },
    showOrganization: {
      default: false,
      type: Boolean
    },

  },
  watch: {
    value: {
      handler() {
        let $this = this
        setTimeout(() => $this.$forceUpdate(), 500)

      },
      immediate: true,
    }
  },
  created() {
    this._loadCalendarData()
        .finally(() => this.loaded = true)
  },
  data: () => ({
    loaded: false,
    error: null,
    tabIndex: 0,
    dialogs: {sms: false},
    statusVariant:
        {"ACTIVE":"success","INQUIRY":"warning","CANCELED":"danger","COMPLETED":"success"},
    isRdv: null,
    popover: {
      rdv_calendar: {
        active: false,
        id: 0
      },

    }
  }),
  computed: {

    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    ...mapGetters('calendar', {calendarTypes: 'getTypes'}),
    ...mapGetters('clients', ['canEdit', 'canReadPhone', 'canEditRep', 'canEditPhone', 'canReadEmail', 'canEditEmail']),
    ...mapGetters('clients', {_allStatus: 'getAllStatus'}),
    ...mapGetters('user', ['myRoleName', 'myRole']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    ...mapGetters('data', {
      _getAffiliateIdByBannerId: 'getAffiliateIdByBannerId',
      _getCampaignByAffId: 'getCampaignByAffId',
      _getBanners: 'getBanners',
      _getAllBranches: 'getAllBranches',
      _getAllAffiliate: 'getAllAffiliate',
    }),
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    allLanguage() {
      return Object.values(this.getAllLanguage)
    },
    allBranches() {
      return Object.values(this._getAllBranches)
    },
    allAffiliates() {
      return Object.values(this._getAllAffiliate)
    },
    clientCampaign() {
      return Object.values(this._getCampaignByAffId(this.client.affId))
    },
    clientBanner() {
      return Object.values(this._getBanners(this.client.affId, this.client.camId))
    },
    client: {
      get() {
        if (this.value == null) {
          console.error('client-details: value (v-model) is null!')
          return {}
        }

        return this.value
        //return {};
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    isCompanyClients() {
      return this.client.companyClient === true
    },
    allAppointments() {
      if (this.appointments == null || !Array.isArray(this.appointments)) {
        console.log('allAppointments is null')
        return []
      }
      console.log('allAppointments' + this.appointments.length)
      return this.appointments
    },
    isNewClient() {
      return typeof this.client.id === 'number' && !isNaN(this.client.id) && this.client.id <= 0
    },
    allStatus() {
      let status = Object.values(this._allStatus)
          .filter(s => s.canUse)
      return [
        //Sort by color
        ...status.filter(s => s.color != null)
            .sort((s1, s2) => (s1.color).localeCompare(s2.color)),
        //Sort by status
        ...status.filter(s => s.color == null)
            .sort((s1, s2) => (s1.status).localeCompare(s2.status))
      ]

    },
    clientBirthday: {
      get() {
        if (this.$moment(this.client.birthday, 'YYYY-MM-DD', true)
            .isValid()) {
          return this.$moment(this.client.birthday)
              .format('DD/MM/YYYY')
        }
        return ''
      },
      set(value) {
        if (this.$moment(value, 'DD/MM/YYYY', true)
            .isValid()) {
          this.client.birthday = this.$moment(value, 'DD/MM/YYYY')
              .format('YYYY-MM-DD')
        }
      }
    },
    clientZip: {
      get() {
        return (this.client.zip) + ' (' + (this.client.dp) + ')'
      },
      set(value) {
        console.log('client zip : ' + value)
        this.client.zip = value
      }
    },
    clientAddr: {
      get() {
        return {}
      },
      set(v) {
        /**
         * {
         * "street_number":"13",
         * "route":"Hackhofergasse",
         * "street_name":"Hackhofergasse",
         * "sublocality_level_1":"Döbling",
         * "sublocality":"Döbling",
         * "political":"AT",
         * "locality":"Wien",
         * "administrative_area_level_1":"Wien",
         * "country":"AT",
         * "postal_code":"1190",
         * "formatted_address":"Hackhofergasse 13, 1190 Wien, Österreich"
         * }
         */
        if (v == null) {
          return
        }

        this.client.address = v['street_name'] + ' ' + v['street_number']
        this.client.country = v['country']
        this.client.zip = v['postal_code']
        this.client.city = v['locality']
      }
    },
    getMaxBirthdayDate() {
      return this.$moment()
          .subtract(18, 'years')
          .format('YYYY-MM-DD')
    },
    getDefaultCalendarType() {
      return (this.myRole.calendar != null ? this.myRole.calendar : null)

    },

  },
  methods: {
    ...mapActions('calendar', {_loadCalendarData: 'loadData'}),
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    formatDate(date) {
      if (!date || !date.$date) return '';
      return this.$moment(date.$date).format('HH:mm DD/MM/YYYY'); // Format as HH:mm DD/MM/YYYY
    },
    /*setField(field,value){

        this.client[field] = value;
        if(this.$v.client[field] != null)
            this.$v.client[field].$touch()

    },*/
    defaultCalendarTitle(client) {

      if (this.isCompanyClients) {
        return (client.companyName != null ? client.companyName + ' ' : '') +
            (client.firstname != null ? client.firstname + ' ' : '') +
            (client.lastname != null ? client.lastname : '')
      } else {
        return (client.firstname != null ? client.firstname + ' ' : '') +
            (client.lastname != null ? client.lastname + ' ' : '')
      }

    },
    toTheContract(value) {
      window.open(`/real_estate/contracts/rent/${value}`, '_blank');
    },
    touch(field) {
      this.$v.$reset()
      //TODO: CHECK PROBLEM WITH VALIDATION
      if (true) return
      if (field == null) {
        this.$v.$touch()
        return
      }
      if (this.$v.client[field] == null) {
        return
      }
      this.$v.client[field].$touch()
    },

    onAffSelect() {
      this.client.camId = 0
      this.client.bannerid = 0
    },
    onCampSelect() {
      this.client.bannerid = 0
    },
    getCalendarTypeName(typeId) {
      if (this.calendarTypes == null || this.calendarTypes[typeId] == null) {
        return ''
      }
      return this.calendarTypes[typeId].name
    },
    generatePassword(e) {
      if (e != null && typeof e.preventDefault === 'function') {
        e.preventDefault()
      }

      this.client.password = Math.random()
          .toString(36)
          .slice(-8)
      this.$forceUpdate()
    },
    setClientAddr(addr) {
      /**
       * {
       * "street_number":"13",
       * "route":"Hackhofergasse",
       * "street_name":"Hackhofergasse",
       * "sublocality_level_1":"Döbling",
       * "sublocality":"Döbling",
       * "political":"AT",
       * "locality":"Wien",
       * "administrative_area_level_1":"Wien",
       * "country":"AT",
       * "postal_code":"1190",
       * "formatted_address":"Hackhofergasse 13, 1190 Wien, Österreich"
       * }
       */
      if (addr == null) {
        return
      }

      this.client.address = addr['formatted_address'].split(',')[0]
      this.client.country = addr['country']
      this.client.zip = addr['postal_code']
      this.client.city = addr['locality']

    },
    saveEvent() {
      this.$root.$emit('crm::submitCalendar')

    },
    dismissPopover() {
      this.$root.$emit('bv::hide::popover')
    },
    validate(field) {
      return this.$v.client[field] != null && this.$v.client[field].$error === true
    },
    dateStr(d) {
      return this.$moment(d)
          .format('DD/MM/YYYY HH:mm')
    },
    onCalendarSubmitted(ans, popover) {
      if ((ans == null || ans.status === true)) {
        // this.dismissPopover();
        this.$emit('calendarUpdated')
        this.$root.$emit('crm::calendar::update')
        if (ans.id != null && ans.id > 0 && popover != null) {
          this.popover[popover].id = ans.id
        }
      }

    },

    print(e, a) {
      console.log(e, a)
    },
    openSmsDialog() {

      this.dialogs.sms = true

    },
    submitSMS() {
      console.log("submitSMS ()")
      this.$root.$emit('crm::submitSms')
    },
    onSubmitted(server) {
      console.log("SMS -> onSubmitted()", server)
      this.dialogs.sms = false;
    }
  },
}
</script>

<style>
.clients-details .md-list > .row {
  min-height: 57px;
}

.clients-details .md-list .md-divider {
  margin-top: 10px;
  margin-bottom: 5px;
}
</style>
