<template>
  <div class="chat-app-window">
    <!--All notes-->
    <perfect-scrollbar class="show-scroll note-history p-0 note-console" style="position: relative" :options="{suppressScrollX:true}">
      <div class="chats" v-bind:style="`height: ${historyMaxHeight}px`">
        <div :class="{'chat-left':itsMe(note.user),'note-selected':value.id===note.id}" v-for="note in allNotes" v-bind:key="note.id" @dblclick="loadNote(note.id)">
          <div class="chat-avatar text-center px-1" style="max-width: 100px;">
            <b-avatar size="36" class="avatar-border-2 box-shadow-1 d-block badge-minimal" variant="light-primary"
                      v-b-tooltip :title="getUserFullName(note.user)"
                      :style="{visibility:( note.sameAsPrevUser !== true ? 'visible' : 'hidden')}"
            >
              <span v-if="note.user != null && note.user > 0">
                {{ getUserAcronyms(note.user, true) }}
              </span>
            </b-avatar>

          </div>
          <div class="chat-body note">
            <div class="chat-content note-detail w-100">
              <div class="note-text" v-html="notesToHtml(note.text)"></div>
              <div class="note-time">
                {{ note.creationtime }}
                <div class="d-inline-block">
                  <i class="fas fa-globe" v-if="note.public"></i>
                  <i class="fas fa-lock" v-else></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <!--New notes-->
    <!-- Message Input -->
    <div class="chat-app-form">
      <b-input-group class="input-group-merge form-send-message">
        <b-input-group>
          <b-form-input v-model="note.text" placeholder="Enter your message"/>
          <b-input-group-append is-text>
            <b-link id="notePermission">
              <i class="fas fa-globe" v-if="note.public"></i>
              <i class="fas fa-lock" v-else></i>
            </b-link>
          </b-input-group-append>
        </b-input-group>
      </b-input-group>
      <span v-if="value.id > 0" class="ml-1"></span>
      <b-button v-if="value.id > 0" variant="danger" type="submit" @click="loadNote(-1)"   style="white-space: nowrap">
        <i class="fas fa-trash"></i>
        <span class="ml-1"></span>
        {{ msg('Delete') }}
      </b-button>
      <span v-if="value.id > 0" class="ml-1"></span>
      <b-button v-if="value.id > 0" variant="warning" type="submit" @click="loadNote(0)" :disabled="!canSave" style="white-space: nowrap">
        <i class="fas fa-times"></i>
        <span class="ml-1"></span>
        {{ msg('Cancel') }}
      </b-button>
      <span class="ml-1"></span>
      <b-button variant="primary" type="submit" @click="save" :disabled="!canSave" style="white-space: nowrap">
        <i class="fas fa-paper-plane"></i>
        <span class="ml-1"></span>
        {{ msg(value.id > 0 ? 'Edit' : 'Save') }}
      </b-button>

      <b-popover target="notePermission" size="md">
        <template slot="title">
          {{ msg('Permissions') }}
          <span style="position: absolute; top: 8px; right: 15px; cursor: pointer;" @click="$root.$emit('bv::hide::popover')"><i class="fas fa-times"></i></span>
        </template>
        <div class="p-2" style="min-width: 250px">
          <div>
            <b-form-checkbox
                v-model="note.public"
                switch
            >{{ note.public ? msg('Public') : msg('Private') }}
            </b-form-checkbox>
          </div>
          <div>
            <b-form-group :label="msg('Name')" label-for="rep-user">
              <v-select
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="newNoteUserPermission"
                  :options="otherUsers"
                  class="w-100"
                  label="name"
                  :reduce="(us) => us.id"
                  multiple
                  :disabled="note.public"
              />
            </b-form-group>
          </div>
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'noteConsole',
  props: {
    historyMaxHeight: {
      default: 600,
      type: Number
    },
    value: {
      type: Object,
      required: true,
    },
    notes: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms', 'getOtherUsers']),
    ...mapGetters('notes', ['hasTypes', 'getTypes', 'getTypeName']),
    ...mapGetters('user', ['myId']),
    otherUsers() {
      return this.getOtherUsers.map(us => ({
        id: us.id,
        name: (us.firstname + ' ' + us.lastname)
      }))
    },
    note: {
      get() {
        if (this.value == null) {
          console.error('Notes: value (v-model) is null!')
          return {}
        }
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    allNotes() {
      let notes = []
      let prevUserNote = null
      this.notes.forEach(note => {
        note = { ...note }
        if (prevUserNote != null && prevUserNote === note.user) {
          note['sameAsPrevUser'] = true
        }
        prevUserNote = note.user
        notes.push(note)
      })
      return notes
    },
    canSave() {
      if (this.note.id <= 0 || this.note.user == this.myId) return true
      return false
    },
    newNoteUserPermission: {
      get() {
        if (this.note.allPermissionUser == null) {
          return []
        }
        return this.note.allPermissionUser.map(p => p.user)
      },
      set(userIds) {

        if (this.note.allPermissionUser == null) {
          return
        }
        //Check if userIds its an array
        if (Array.isArray(userIds)) {
          //check if this newNoteUserPermission (from get()) is equal to userIds (by converting to string)
          if (this.newNoteUserPermission.join(',') !== userIds.join(','))
              //convert userIds to map of objects and update note.allPermissionUser;
          {
            this.note.allPermissionUser = userIds.map(u => ({
              user: u,
              note: this.note.id
            }))
          }
          return
        }
        // in case that userIds its not an array check if user id exist (in order to prevent a recurse)
        if (this.note.allPermissionUser.findIndex(p => p.user == userIds) < 0) {
          this.note.allPermissionUser = [{
            user: userIds,
            note: this.note.id
          }]
        }
      }
    }
  },
  methods: {

    itsMe(userId) {
      return userId === this.myId
    },
    loadNote(id) {
      this.$emit('loadNote', id)
    },
    notesToHtml(text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    removeNote() {
      //I dont know why, but doesnt work
      this.$emit('delNote')
    },
    save() {
      this.$emit('save')
    },
  }
}
</script>

<style>
.note-console{
  background-color: #fafafa;
}
.note-selected{
  background-color: #001ef50d;
}
body.dark-layout .note-console{
  background-color: #161d31;
}
</style>
